import request from '@/utils/request'
const BASE_URL = '/wjmap'
/**
 * 行政区域
 * searchWord为行政地区编码
 * @returns
 */
export const Administrative_Division_service = (searchWord) => {
  return request.get(`${BASE_URL}/district/v1/search?&keyword=${searchWord}&key=B6ZBZ-NCSKD-XOK4F-HFV4X-IU2K3-G4FG2&get_polygon=1`)
}

/**
 * keyword 搜索关键字
 * boundary=nearby(lat,lng,radius[, auto_extend])
            子参数：
            lat,lng：搜索中心点的经纬度，格式顺序为纬度在前，经度在后
            radius：搜索半径，单位：米，取值范围：10到1000
            auto_extend：[可选] 当前范围无结果时，是否自动扩大范围，取值：
            0 不扩大
            1 [默认] 自动扩大范围（依次按照按1公里、2公里、5公里，
            最大到全城市范围搜索）
 */
export const Place_name_search = (keyWord, x, y) => {
  return request.get(
    `${BASE_URL}/place/v1/search?boundary=nearby(${y},${x},1000)&keyword=${keyWord}&page_size=20&page_index=1&key=B6ZBZ-NCSKD-XOK4F-HFV4X-IU2K3-G4FG2`
  )
}

