import { Style, Circle, Stroke, Fill, Text } from "ol/style";
import Icon from "ol/style/Icon";

/**
 * Styles
 */
class Styles {

  // 展示监测点样式
  createSitePointStyle(name) {
    return new Style({
      // 点样式
      image: new Circle({
        // 点半径
        radius: 10,
        // 点的边框，
        stroke: new Stroke({
          color: 'rgba(65, 97, 235,1)',
          width: 1
        }),
        // 缩放比
        scale: 1,
        // 填充色
        fill: new Fill({
          color: 'rgba(5, 97, 235,0.6)'
        })
      }),
      text: new Text({
        text: name,
        font: '14px font-size',
        fill: new Fill({
          // 设置字体颜色
          color: '#000'
        }),
        offsetY: 18 // 设置文字偏移量
      })
    })
  }

  createSitePointStyle2(name) {
    return new Style({
      // 点样式
      image: new Circle({
        // 点半径
        radius: 10,
        // 点的边框，
        stroke: new Stroke({
          color: 'rgba(65, 97, 235,1)',
          width: 1
        }),
        // 缩放比
        scale: 1,
        // 填充色
        fill: new Fill({
          color: 'rgba(255 ,165, 0,0.6)'
        })
      }),
      text: new Text({
        text: name,
        font: '14px font-size',
        fill: new Fill({
          // 设置字体颜色
          color: '#000'
        }),
        offsetY: 18 // 设置文字偏移量
      })
    })
  }

  // 新增监测点样式
  static point = new Style({
    // 点样式
    image: new Circle({
      // 点半径
      radius: 10,
      // 点的边框，
      stroke: new Stroke({
        color: 'rgba(65, 97, 235,1)',
        width: 1
      }),
      // 缩放比
      scale: 1,
      // 填充色
      fill: new Fill({
        color: 'rgba(5, 97, 235,0.6)'
      })
    }),
  });

  //标记点样式
  static iconStyle = new Style({
    image: new Icon({
      src: require("../assets/icon/map/marker_icon.png"),
      anchor: [0.5, 1],  //default[0.5, 0.5]  Anchor. Default value is the icon center.
    }),
  });

  // 点样式
  static iconStyleCircle = new Circle({
    // 点半径
    radius: 15,
    // 点的边框，
    stroke: new Stroke({
      color: 'red',
      width: 3,
    }),
    // 缩放比
    scale: 1,
    // 填充色
    fill: new Fill({
      color: 'green',
    })
  });

  // let styleHh = new Style({
  //   image: new Icon({
  //     src: require('@/assets/icon/map/marker_icon.png'),
  //     anchor: [0.5, 1],
  //     fill: new Fill({
  //       color: '#f40'
  //     })
  //   }),
  //   fill: new Fill({
  //     color: '#eee'
  //   }),
  //   text: new Text({
  //     text: '监测点',
  //     font: '14px font-size',
  //     fill: new Fill({
  //       // 设置字体颜色
  //       color: '#000'
  //     }),
  //     offsetY: 10 // 设置文字偏移量
  //   })
  // })
  constructor() {
  }
}


export default Styles;



