<template>
  <div>
    <div class="point"></div>
    <div class="title">{{ title }}</div>
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {}
  }
</script>
<style lang="less" scoped>
  * {
    display: flex;
    align-items: center;
  }
  .point {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(64, 158, 255, 1) 30%, rgba(64, 158, 255, 0.5) 10%);
    margin-right: 6px;
  }
  .title {
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
  }
</style>
