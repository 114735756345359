<template>
  <div id="map" ref="map">
    <!-- 地区河流搜索框 -->
    <div id="search">
      <el-autocomplete
        v-model="nameSearch"
        style="width: 300px"
        placeholder="请输入地区名称"
        :fetch-suggestions="querySearch"
        :trigger-on-focus="false"
        clearable
        @select="handleSelect"
      >
        <template slot-scope="{ item }">
          <div>
            <div class="search_title">{{ item.title }}</div>
            <span class="search_addr">{{ item.address }}</span>
          </div>
        </template>
      </el-autocomplete>
    </div>
    <!-- 工具箱 -->
    <div class="toolBox" @click="clickBox">
      <img
        class="toolBoxIcon"
        :src="
          checkedTool ? require('@/assets/icon/map/toolBox_selected.png') : require('@/assets/icon/map/toolBox.png')
        "
        alt
      />
      <div :style="checkedTool ? 'color:blue' : ''">工具箱</div>
    </div>
    <!-- 工具 -->
    <div v-show="checkedTool" class="tools">
      <div class="toolIcon" @click="measure('LineString')">
        <img class="toolBoxIcon" :src="require('@/assets/icon/map/measure_length_selected.png')" alt />
        <div>测距</div>
      </div>
      <div class="toolIcon" @click="measure('Polygon')">
        <img class="toolBoxIcon" :src="require('@/assets/icon/map/measure_area_selected.png')" alt />
        <div>测面</div>
      </div>
      <div class="toolIcon" @click="getLayer(0)">
        <img class="toolBoxIcon" src="@/assets/icon/map/layer.png" alt />
        <div :style="checkedLayer ? 'color:blue' : ''">图层</div>
      </div>
      <div class="toolIcon" @click="getXingZhengQuYu">
        <img class="toolBoxIcon" src="@/assets/icon/map/xingzhengquyu_selected.png" alt />
        <div>行政区域</div>
      </div>
    </div>
    <!-- 图层 -->
    <div v-show="checkedLayer" class="layerTools">
      <div @click="getLayer(1)">矢量图</div>
      <div @click="getLayer(2)">卫星图</div>
    </div>

    <!-- 弹出框 -->
    <div v-for="(item, index) in popupsData" :id="index + 'popup'" :ref="index + 'popup'" :key="index" class="ol-popup">
      <div class="popupName">{{ item.name }}</div>
      <el-carousel v-if="item.list && item.list.length > 0" height="120px" :autoplay="false" indicator-position="none">
        <el-carousel-item v-for="(j, i) in item.list" :key="i">
          <div style="font-size: 10px">{{ j.name }} {{ j.score }}分</div>
          <div v-if="j.pic && j.pic.length > 0">
            <el-image style="width: 100%; height: 100px" :src="j.pic[0]" :preview-src-list="j.pic" fit="scale-down" />
          </div>
          <div v-else class="noPic">暂无照片</div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
  import 'ol/ol.css'
  import '@/assets/css/map.less'

  import { Feature, geom } from 'ol'
  import Overlay from 'ol/Overlay'
  import WKT from 'ol/format/WKT'
  import VectorLayer from 'ol/layer/Vector'
  import Vector from 'ol/source/Vector'
  import Point from 'ol/geom/Point'
  import { Style, Circle, Fill, Stroke, Text } from 'ol/style'
  import {
    Point as GeomPoint,
    LineString as GeoLineString,
    MultiPolygon as GeoMultiPolygon,
    Polygon,
    LineString
  } from 'ol/geom'
  import { Draw, Modify, Select } from 'ol/interaction'

  //openlayers
  import { unByKey } from 'ol/Observable'
  //Map
  import Map from '@/openlayers/map'
  import Layers from '@/openlayers/Layers'
  import Styles from '@/openlayers/Styles'
  //api
  import { Administrative_Division_service, Place_name_search } from '@/api/map.js'
  import { File } from '@/api/file/index.js'
  import { getIndicator } from '@/views/healthAssessment/IndicatorEvaluate/indicatorScore'

  export default {
    name: '',
    components: {},
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        //地区搜索
        nameSearch: '',
        //地图
        map: null,
        //默认经纬度 苏州
        x: 120.645728,
        y: 31.138525,

        showAddPointFrom: false, //展示新增坐标点
        newPoint: {}, //当前新增标记点对象
        pointLayer: null, //新增标记点 图层
        pointsLayer: null, //标记点图层

        showAddLineFrom: false, //展示新增标记线
        newLine: {}, //当前新增标记线对象
        newLineLayer: null, //新增标记线 图层
        linesLayer: null, //标记线图层

        showAddAreaFrom: false, //展示新增面
        newAreaLayer: null, //新增标记面 图层
        areasLayer: null, //标记面图层

        //工具
        checkedTool: false, //工具箱
        checkedLength: false, //测距中
        checkedArea: false, //侧面中
        checkedLayer: false,

        scorePointsLayer: null, //采样点图层
        popupsData: []
      }
    },
    computed: {},
    watch: {
      showAddPointFrom: function (newData, oldData) {
        if (newData) {
          this.PointMsg = this.$message({
            message: '地图标记点操作中...',
            center: true,
            type: 'success',
            duration: 0
          })
        } else {
          this.PointMsg.close()
        }
      },
      showAddAreaFrom: function (newData, oldData) {
        if (newData) {
          this.AreaMsg = this.$message({
            message: '地图标记面操作中...',
            center: true,
            type: 'success',
            duration: 0
          })
        } else {
          this.AreaMsg.close()
        }
      },
      showAddLineFrom: function (newData, oldData) {
        if (newData) {
          this.LineMsg = this.$message({
            message: '地图标记线操作中...',
            center: true,
            type: 'success',
            duration: 0
          })
        } else {
          this.LineMsg.close()
        }
      }
    },
    created() {},
    mounted() {
      // window.addEventListener('resize', () => {
      //   console.log('11', this.map);
      //   if (this.map) {
      //     this.map.updateSize()
      //   }
      // })

      this.initalMap('map')
    },

    methods: {
      //初始化地图、设置中心点坐标
      initalMap(id) {
        //初始化数据
        Map.sendThis(this)
        //初始化图层 天地图-矢量底图和矢量标记图
        const layers = new Layers()
        this.layers = layers.getLayers()
        //创建地图
        Map.createBaseMap(id)
        const code = '320509' //行政编码
        Administrative_Division_service(code).then((res) => {
          if (res.status === 200) {
            //设置经纬度
            const place = res.data.result[0]
            const l = place[0]
            // console.log('polygon', l.polygon);

            this.showXingZhengArea(l.polygon)
            this.x = l.location.lng
            this.y = l.location.lat

            this.map.getView().animate({
              center: [this.x, this.y], // 中心点
              zoom: 14, // 缩放级别
              duration: 1000 // 缩放持续时间，默认不需要设置
            })
          }
        })
        console.log('123', this.map.getView().getResolution())
      },
      //地图地区搜索
      querySearch(queryString, cb) {
        this.nameSearch = queryString
        Place_name_search(queryString, this.x, this.y).then((res) => {
          if (res.status === 200) {
            cb(res.data.data)
          }
        })
      },
      //选择搜索地区，设置新的地图中心点
      handleSelect(item) {
        console.log('handleSelect', this.map.getView().getResolution())
        let resolution = this.map.getView().getResolution()
        this.map.getView().animate({
          // 只设置需要的属性即可
          center: [item.location.lng, item.location.lat], // 中心点
          // zoom: 15, // 缩放级别
          duration: 1000, // 缩放持续时间，默认不需要设置
          resolution
        })
      },
      //展示行政区域框
      showXingZhengArea(data) {
        let gemo = ''
        data[0].forEach((e, index) => {
          if (index % 2 === 0) {
            gemo += e + ' '
          } else {
            gemo += e + ','
          }
        })
        const gemoArr = gemo.split(',')
        let dataSource = []
        gemoArr.forEach((i) => {
          const j = i.split(' ')
          dataSource.push([Number(j[0]), Number(j[1])])
        })
        dataSource.pop()

        var feature = new Feature({
          geometry: new LineString(dataSource)
        })
        let areaStyle = new Style({
          //边框样式
          stroke: new Stroke({
            color: 'rgba(65, 97, 235,1)',
            width: 3,
            lineDash: [10, 5] // 重点在这
          })
        })
        feature.setStyle(areaStyle)
        this.XingZhengArea = new VectorLayer({
          source: new Vector({
            features: [feature]
          })
          // visible: false
        })
        this.XingZhengArea.setZIndex(4)
        this.map.addLayer(this.XingZhengArea)
      },
      //展示行政区域框
      getXingZhengQuYu() {
        this.checkedTool = false
        this.checkedLayer = false
        this.XingZhengArea.setVisible(!this.XingZhengArea.getVisible())
      },
      //测距、侧面
      measure(type) {
        this.checkedTool = false
        this.checkedLayer = false
        if (type === 'LineString') {
          this.checkedArea = false
          this.checkedLength = !this.checkedLength
        } else {
          this.checkedLength = false
          this.checkedArea = !this.checkedArea
        }
        //type：测距、侧面
        //statusFlag：选择操作、取消操作
        Map.measure(type, true)
      },
      //图层
      getLayer(e) {
        if (e === 0) {
          this.checkedLayer = !this.checkedLayer
          return
        }
        this.checkedTool = false
        this.checkedLayer = false
        //矢量图
        if (e === 1) {
          this.layers[0].setVisible(true)
          this.layers[1].setVisible(false)
        }
        //卫星图
        if (e === 2) {
          this.layers[1].setVisible(true)
          this.layers[0].setVisible(false)
        }
        console.log('getLayer', this.map.getView().getResolution())
        let resolution = this.map.getView().getResolution()
        this.map.getView().setResolution(resolution)
      },
      clickBox() {
        this.checkedTool = !this.checkedTool
        if (!this.checkedTool) {
          this.checkedLayer = false
        }
      },
      //河湖对象定位
      toNewView(row) {
        let format = new WKT()
        let feature = format.readFeature(row.geom, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:4326'
        })
        var geo = feature.getGeometry()
        var extent = geo.getExtent()

        console.log('222', this.map.getView().getResolution())
        let resolution = this.map.getView().getResolutionForExtent(extent, this.map.getSize())
        this.map.getView().fit(extent, {
          // duration: 2000, //动画的持续时间,
          // maxZoom: 14
        })
        this.map.getView().setResolution(resolution)
      },

      // =============================点操作=============================
      //显示监测点
      showMarkPoints(data) {
        this.map.removeLayer(this.pointsLayer)

        const styles = new Styles()
        let pointList = []
        data.forEach((item) => {
          const lon = item.lon
          const lat = item.lat
          const feature = new Feature({
            geometry: new Point([lon, lat])
          })
          feature.setProperties(item)
          feature.setStyle(styles.createSitePointStyle(item.name))
          pointList.push(feature)
        })
        this.pointsLayer = new VectorLayer({
          source: new Vector({
            features: [...pointList]
          })
        })
        this.pointsLayer.setZIndex(10)
        this.map.addLayer(this.pointsLayer)
      },
      //点击地图 添加监测点
      addMarkPoint() {
        unByKey(this.clickEventEditFeature)

        this.showAddPointFrom = true
        // 设置添加标记点图层
        this.pointLayer = new VectorLayer({
          source: new Vector()
        })
        this.map.addLayer(this.pointLayer)

        // 绑定事件
        this.clickEvent = this.map.on('singleclick', (event) => {
          // 创建feature
          const feature = new Feature({
            geometry: new Point(event.coordinate)
          })
          //设置 图表的样式
          feature.setStyle(Styles.point)
          this.pointLayer.getSource().clear()
          this.pointLayer.getSource().addFeatures([feature])

          // 站点转换为WKT格式 POINT(120.884526116333 32.0249844342041)
          const wkt = new WKT().writeGeometry(new GeomPoint(event.coordinate), {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326'
          })
          //传递标记点信息数据
          this.$emit('getPointWKT', { wkt, coordinate: event.coordinate })
        })
      },
      //取消添加监测点
      cancelAddPointFrom() {
        this.showAddPointFrom = false
        unByKey(this.clickEvent)
        this.map.removeLayer(this.pointLayer)
      },

      //展示线下采样点
      showScorePoints(name, data) {
        //显示弹框
        this.initPopup(name, data)

        //显示采样点
        this.map.removeLayer(this.scorePointsLayer)

        const styles = new Styles()
        let pointList = []
        data.forEach((item) => {
          const lon = item.lon
          const lat = item.lat
          const feature = new Feature({
            geometry: new Point([lon, lat])
          })
          feature.setProperties(item)
          feature.setStyle(styles.createSitePointStyle2(item.name))
          pointList.push(feature)
        })
        this.scorePointsLayer = new VectorLayer({
          source: new Vector({
            features: [...pointList]
          })
        })
        this.scorePointsLayer.setZIndex(10)
        this.map.addLayer(this.scorePointsLayer)
      },

      //展示采样点popup弹框
      initPopup(name, data) {
        this.popupsData = []
        if (name === '岸坡稳定性') {
          this.formmaterAnPoWenDing(data)
          data.forEach((i) => {
            let popup = { name, list: [] }
            popup.lat = i.lat
            popup.lon = i.lon
            if (popup.lat !== undefined) {
              if (i.anPoShuiTuLiuShiLevel && i.anPoShuiTuLiuShiJoinFlag) {
                popup.list.push({
                  name: '岸坡水土流失：' + this.getIndicatorName(i.anPoShuiTuLiuShiLevel),
                  score: this.getIndicatorScore(i.anPoShuiTuLiuShiLevel),
                  pic: i.anPoShuiTuLiuShiPngCodeList
                })
              }
              if (i.binAnChongShuaLevel && i.binAnChongShuaJoinFlag) {
                popup.list.push({
                  name: '滨岸冲涮：' + this.getIndicatorName(i.binAnChongShuaLevel),
                  score: this.getIndicatorScore(i.binAnChongShuaLevel),
                  pic: i.binAnChongShuaPngCodeList
                })
              }
              if (i.anTaLevel && i.anTaJoinFlag) {
                popup.list.push({
                  name: '岸塌：' + this.getIndicatorName(i.anTaLevel),
                  score: this.getIndicatorScore(i.anTaLevel),
                  pic: i.anTaPngCodeList
                })
              }
              if (popup.list.length > 0) {
                this.popupsData.push(popup)
              }
            }
          })
        }
        if (name === '岸坡环境整治程度') {
          this.formmaterAnPoHuanJing(data)
          data.forEach((i) => {
            let popup = { name, list: [] }
            popup.lat = i.lat
            popup.lon = i.lon
            if (popup.lat !== undefined) {
              if (i.luanJianLevel && i.luanJianJoinFlag) {
                popup.list.push({
                  name: '乱建：' + this.getIndicatorName(i.luanJianLevel),
                  score: this.getIndicatorScore(i.luanJianLevel),
                  pic: i.luanJianPngCodeList
                })
              }

              if (i.luanKenLevel && i.luanKenJoinFlag) {
                popup.list.push({
                  name: '乱垦：' + this.getIndicatorName(i.luanKenLevel),
                  score: this.getIndicatorScore(i.luanKenLevel),
                  pic: i.luanKenPngList
                })
              }

              if (i.luanQiLevel && i.luanQiJoinFlag) {
                popup.list.push({
                  name: '乱弃：' + this.getIndicatorName(i.luanQiLevel),
                  score: this.getIndicatorScore(i.luanQiLevel),
                  pic: i.luanQiPngList
                })
              }

              if (i.luanZhanLevel && i.luanZhanJoinFlag) {
                popup.list.push({
                  name: '乱占：' + this.getIndicatorName(i.luanZhanLevel),
                  score: this.getIndicatorScore(i.luanZhanLevel),
                  pic: i.luanZhanPngList
                })
              }
              if (popup.list.length > 0) {
                this.popupsData.push(popup)
              }
            }
          })
        }
        if (name === '水体整洁程度') {
          this.formmaterShuiTi(data)
          data.forEach((i) => {
            let popup = { name, list: [] }
            popup.lat = i.lat
            popup.lon = i.lon
            console.log('12', i)
            if (popup.lat !== undefined) {
              if (i.piaoFuWuLevel && i.piaoFuWuJoinFlag) {
                popup.list.push({
                  name: '漂浮物：' + this.getIndicatorName(i.piaoFuWuLevel),
                  score: this.getIndicatorScore(i.piaoFuWuLevel),
                  pic: i.piaoFuWuPngList
                })
              }
              if (i.touMingDuLevel && i.touMingDuJoinFlag) {
                popup.list.push({
                  name: '透明度：' + this.getIndicatorName(i.touMingDuLevel),
                  score: this.getIndicatorScore(i.touMingDuLevel),
                  pic: i.touMingDuPngList
                })
              }
              if (i.xiuHeWeiLevel && i.xiuHeWeiJoinFlag) {
                popup.list.push({
                  name: '嗅和味：' + this.getIndicatorName(i.xiuHeWeiLevel),
                  score: this.getIndicatorScore(i.xiuHeWeiLevel),
                  pic: i.xiuHeWeiPngList
                })
              }
              if (popup.list.length > 0) {
                this.popupsData.push(popup)
              }
            }
          })
        }
        console.log('popupsData', this.popupsData)

        //跳转视图
        if (this.popupsData.length > 0) {
          this.map.getView().animate({
            center: [this.popupsData[0].lon, this.popupsData[0].lat], // 中心点
            zoom: 18, // 缩放级别
            duration: 0 // 缩放持续时间，默认不需要设置
          })
        }

        this.$nextTick(() => {
          this.popupsData.forEach((p, index) => {
            //设置弹框popup
            let element = this.$refs[index + 'popup']
            let overlay = null
            overlay = new Overlay({
              id: index,
              element: element[0],
              positioning: 'center-center'
            })
            overlay.setPosition([p.lon, p.lat])
            this.map.addOverlay(overlay)
          })
        })
      },
      //岸坡稳定性 获取图片列表
      async formmaterAnPoWenDing(data) {
        let promise = []
        data.forEach((item) => {
          // 岸坡水土流失
          if (item.anPoShuiTuLiuShiPngCode && !item.anPoShuiTuLiuShiPngCode.includes('tmp')) {
            item.anPoShuiTuLiuShiPngCodeList = []
            let arr = item.anPoShuiTuLiuShiPngCode.split(',')
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.anPoShuiTuLiuShiPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
          }
          // 滨岸
          if (item.binAnChongShuaPngCode && !item.binAnChongShuaPngCode.includes('tmp')) {
            item.binAnChongShuaPngCodeList = []
            let arr = item.binAnChongShuaPngCode.split(',')
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.binAnChongShuaPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
          }
          // 岸塌
          if (item.anTaPngCode && !item.anTaPngCode.includes('tmp')) {
            item.anTaPngCodeList = []
            let arr = item.anTaPngCode.split(',')
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.anTaPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
          }
        })
        await Promise.all(promise)
      },
      //岸坡环境整洁程度 获取图片列表
      async formmaterAnPoHuanJing(data) {
        let promise = []
        data.forEach((item) => {
          // 乱垦
          if (item.luanKenPng && !item.luanKenPng.includes('tmp')) {
            item.luanKenPngList = []
            let arr = item.luanKenPng.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.luanKenPngList.push(res.data.data)
              })
              promise.push(a)
            })
          }
          // 乱占
          if (item.luanZhanPng && !item.luanZhanPng.includes('tmp')) {
            item.luanZhanPngList = []
            let arr = item.luanZhanPng.split(',')
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.luanZhanPngList.push(res.data.data)
              })
              promise.push(a)
            })
          }
          // 乱建
          if (item.luanJianPng && !item.luanJianPng.includes('tmp')) {
            item.luanJianPngCodeList = []
            let arr = item.luanJianPng.split(',')
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.luanJianPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
          }
          // 乱弃
          if (item.luanQiPng && !item.luanQiPng.includes('tmp')) {
            item.luanQiPngList = []
            let arr = item.luanQiPng.split(',')
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.luanQiPngList.push(res.data.data)
              })
              promise.push(a)
            })
          }
        })
        await Promise.all(promise)
      },
      //水体整洁程度 获取图片列表
      async formmaterShuiTi(data) {
        let promise = []
        data.forEach((item) => {
          // 嗅和味
          if (item.xiuHeWeiPng && !item.xiuHeWeiPng.includes('tmp')) {
            item.xiuHeWeiPngList = []
            let arr = item.xiuHeWeiPng.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.xiuHeWeiPngList.push(res.data.data)
              })
              promise.push(a)
            })
          }
          // 漂浮废弃物
          if (item.piaoFuWuPng && !item.piaoFuWuPng.includes('tmp')) {
            item.piaoFuWuPngList = []
            let arr = item.piaoFuWuPng.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.piaoFuWuPngList.push(res.data.data)
              })
              promise.push(a)
            })
          }
          // 透明度
          if (item.touMingDuPng && !item.touMingDuPng.includes('tmp')) {
            item.touMingDuPngList = []
            let arr = item.touMingDuPng.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.touMingDuPngList.push(res.data.data)
              })
              promise.push(a)
            })
          }
        })
        await Promise.all(promise)
      },

      getIndicatorName(str) {
        const res = getIndicator(str)
        return res ? res.name : ''
      },
      getIndicatorScore(str) {
        const res = getIndicator(str)
        return res ? res.score : ''
      },
      // =============================面操作=============================
      //地图显示标记面
      showMarkArea(data) {
        this.map.removeLayer(this.areasLayer)
        let areaList = []
        data
          .filter((i) => i.geom)
          .forEach((item) => {
            let wkt = item.geom
            let format = new WKT()
            let feature = format.readFeature(wkt, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:4326'
            })
            let areaStyle = new Style({
              //填充样式
              fill: new Fill({
                color: 'rgba(5, 97, 235,0.6)'
              }),
              //边框样式
              stroke: new Stroke({
                color: 'rgba(65, 97, 235,1)',
                width: 3
              }),
              text: new Text({
                text: item.name,
                font: '18px sans-serif',
                fill: new Fill({
                  // 设置字体颜色
                  color: '#000'
                })
                //   offsetY: 10 // 设置文字偏移量
              })
            })
            feature.setStyle(areaStyle)
            feature.setProperties(item)
            areaList.push(feature)
          })
        this.areasLayer = new VectorLayer({
          source: new Vector({
            features: [...areaList]
          })
        })
        this.areasLayer.setZIndex(5)
        this.map.addLayer(this.areasLayer)
      },
      //地图添加标记面
      addMarkArea() {
        unByKey(this.clickEventEditFeature)
        this.showAddAreaFrom = true

        //新增标记面
        this.newAreaLayer = new VectorLayer({
          source: new Vector()
        })
        this.map.addLayer(this.newAreaLayer)

        const draw = new Draw({
          type: 'MultiPolygon',
          source: this.newAreaLayer.getSource()
        })
        this.draw = draw
        this.map.addInteraction(this.draw)

        this.draw.once('drawend', (event) => {
          const wkt = new WKT().writeGeometry(new GeoMultiPolygon(event.feature.getGeometry().getCoordinates()), {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326'
          })
          //传递位置信息数据
          this.$emit('getAreaWKT', wkt)
          unByKey(this.clickEvent)
          this.map.removeInteraction(this.draw)
          this.editArea(this.newAreaLayer)
        })
      },
      //编辑面地理信息
      editArea(layer) {
        let select = new Select({ layers: [layer] })
        let features = select.getFeatures()
        let modify = new Modify({
          features: features
        })
        select.setActive(true)
        modify.setActive(true)

        this.select = select
        this.modify = modify

        this.map.addInteraction(this.select)
        this.map.addInteraction(this.modify)

        features.on('add', (event) => {
          const feature = event.element
          feature.on('change', (evt) => {
            const wkt = new WKT().writeGeometry(new GeoMultiPolygon(evt.target.getGeometry().getCoordinates()), {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:4326'
            })
            //传递位置信息数据
            this.$emit('getAreaWKT', wkt)
          })
        })
      },
      //点击保存添加标记面后地图操作
      saveAddAreaFrom() {
        this.cancelAddAreaFrom()
      },
      //点击取消添加标记面后地图操作
      cancelAddAreaFrom() {
        this.showAddAreaFrom = false
        this.cancelEditFeature()
        this.map.removeLayer(this.newAreaLayer)
      },

      // =============================编辑点面=============================
      editFeature(obj) {
        unByKey(this.clickEvent)
        this.clickEventEditFeature = this.map.on(
          'click',
          (evt) => {
            let flag = false
            this.map.forEachFeatureAtPixel(evt.pixel, (a) => {
              const type = a.getGeometry().getType() // Point\MultiPolygon

              // console.log('type', type)
              // console.log('obj', obj)
              // console.log('a: ', a)
              // console.log('a.values_.objId', a.values_.objId)
              //点
              if (type === 'Point') {
                //点击点时 判断该监测点是否属于编辑的对象下的检测点
                if (obj.id === a.values_.objId) {
                  //传递位置信息数据
                  this.$emit('setPoint', a.values_)
                  flag = true
                }
              } else if (type === 'MultiPolygon') {
                //面
                if (obj.id === a.values_.id && !flag) {
                  // console.log('1', obj.id)
                  // console.log('2', a.values_.id)
                  this.$emit('setArea')
                  this.editArea(this.areasLayer)
                }
              } else if (type === 'LineString') {
                console.log('修改线条')
              }
            })
          },
          { hitTolerance: 180 } //hitTolerance: 以css像素为单位的命中检测公差。在给定位置周围半径内的像素将被检查特征。
        )
      },
      // 取消编辑
      cancelEditFeature() {
        this.map.removeInteraction(this.draw)
        this.map.removeInteraction(this.select)
        this.map.removeInteraction(this.modify)

        unByKey(this.clickEvent)
        unByKey(this.clickEventEditFeature)
      },

      // =============================线操作=============================
      //线
      addMarkLine() {
        console.log('添加操作线')

        // unByKey(this.clickEventEditFeature);
        // this.cancelAddAreaFrom();
        // this.showAddPointFrom = true;

        // 设置添加标记线图层
        this.newLineLayer = new VectorLayer({
          source: new Vector(),
          style: new Style({
            //线条宽度、颜色
            stroke: new Stroke({
              color: '#00008B',
              width: 5
            })
          })
        })
        this.map.addLayer(this.newLineLayer)

        this.draw = new Draw({
          type: 'LineString',
          source: this.newLineLayer.getSource()
        })
        this.map.addInteraction(this.draw)

        this.draw.on('drawend', (event) => {
          const i = new WKT().writeGeometry(new GeoLineString(event.feature.getGeometry().getCoordinates()), {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326'
          })
          this.map.removeInteraction(this.draw)
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 150px;
  }

  .ol-popup:after,
  .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }

  .ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }

  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
  }

  .ol-popup-closer:after {
    content: '✖';
  }

  .popupName {
    font-size: 12px;
  }

  .noPic {
    text-align: center;
    line-height: 80px;
  }
</style>
