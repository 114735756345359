 const indicatorScore = [
    //=============================================岸坡环境整治
    {
        name: '面积<200㎡',
        scoreStr: 'luanKen_small',
        score: -1
    },
    {
        name: '面积200~1000㎡',
        scoreStr: 'luanKen_middle',
        score: -3
    },
    {
        name: '面积>1000㎡',
        scoreStr: 'luanKen_weight',
        score: -5
    },
    {
        name: '长度<5m',
        scoreStr: 'luanZhan_small',
        score: -1
    },
    {
        name: '长度5~50m',
        scoreStr: 'luanZhan_middle',
        score: -3
    },
    {
        name: '长度>50m',
        scoreStr: 'luanZhan_weight',
        score: -5
    },
    {
        name: '面积<5㎡',
        scoreStr: 'luanJian_small',
        score: -1
    },
    {
        name: '面积5~20㎡',
        scoreStr: 'luanJian_middle',
        score: -3
    },
    {
        name: '面积>20㎡',
        scoreStr: 'luanJian_weight',
        score: -5
    },
    {
        name: '面积<5㎡',
        scoreStr: 'luanQi_small',
        score: -1
    },
    {
        name: '面积5~100㎡',
        scoreStr: 'luanQi_middle',
        score: -3
    },
    {
        name: '面积>100㎡',
        scoreStr: 'luanQi_weight',
        score: -5
    },

    //=============================================岸坡稳定性
    {
        name: '轻度',
        scoreStr: 'shuiTuLiuShi_small',
        score: -1
    },
    {
        name: '中等',
        scoreStr: 'shuiTuLiuShi_middle',
        score: -3
    },
    {
        name: '严重',
        scoreStr: 'shuiTuLiuShi_weight',
        score: -5
    },
    {
        name: '轻度',
        scoreStr: 'chongShuaQinShi_small',
        score: -1
    },
    {
        name: '中等',
        scoreStr: 'chongShuaQinShi_middle',
        score: -3
    },
    {
        name: '严重',
        scoreStr: 'chongShuaQinShi_weight',
        score: -5
    },
    {
        name: '轻度',
        scoreStr: 'taAn_small',
        score: -5
    },
    {
        name: '中等',
        scoreStr: 'taAn_middle',
        score: -10
    },
    {
        name: '严重',
        scoreStr: 'taAn_weight',
        score: -20
    },


    //=============================================水体整洁程度
    {
        name: '轻度',
        scoreStr: 'xiuHeWei_small',
        score: -1
    },

    {
        name: '明显',
        scoreStr: 'xiuHeWei_middle',
        score: -3
    },

    {
        name: '强烈',
        scoreStr: 'xiuHeWei_weight',
        score: -5
    },

    {
        name: '<10㎡',
        scoreStr: 'piaoFuWu_small',
        score: -1
    },
    {
        name: '10~50㎡',
        scoreStr: 'piaoFuWu_middle',
        score: -3
    },
    {
        name: '>50㎡',
        scoreStr: 'piaoFuWu_weight',
        score: -5
    },
    {
        name: '<0.5m',
        scoreStr: 'touMingDu_small',
        score: -1
    },
    {
        name: '0.15~0.3m',
        scoreStr: 'touMingDu_middle',
        score: -3
    },
    {
        name: '>0.3m',
        scoreStr: 'touMingDu_weight',
        score: -5
    },
]

export const getIndicator = (scoreStr) => {
    let i = indicatorScore.find((item) => {
        return item.scoreStr === scoreStr
    })
    return i
}