import TileLayer from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'

import WMTS from 'ol/source/WMTS'
import WMTSTileGrid from 'ol/tilegrid/WMTS'

import { get as getProjection } from 'ol/proj.js'
import { getWidth, getTopLeft } from 'ol/extent.js'
import { MY_MAP_LEVEL_NUM } from '@/global'
/**
 * 地图图层
 */
class Layers {
  getLayers() {
    const tiandi_base_map = new TileLayer({
      source: new XYZ({
        name: '天地图-矢量底图',
        url:
          'http://' +
          MY_MAP_LEVEL_NUM +
          '.tianditu.com/DataServer?T=vec_c&x={x}&y={y}&l={z}&tk=26ee4341dc4c0c4269d2222be779e168',
        // crossOrigin: 'anonymous',
        projection: 'EPSG:4326'
      }),
      visible: true
    })
    const tiandi_image_map = new TileLayer({
      source: new XYZ({
        name: '天地图-影像底图',
        url:
          'http://' +
          MY_MAP_LEVEL_NUM +
          '.tianditu.com/DataServer?T=img_c&x={x}&y={y}&l={z}&tk=26ee4341dc4c0c4269d2222be779e168',
        // crossOrigin: 'anonymous',
        projection: 'EPSG:4326'
      }),
      visible: false
    })

    const tiandi_base_tag_map = new TileLayer({
      source: new XYZ({
        name: '天地图-矢量标记',
        url:
          'http://' +
          MY_MAP_LEVEL_NUM +
          '.tianditu.com/DataServer?T=cva_c&x={x}&y={y}&l={z}&tk=26ee4341dc4c0c4269d2222be779e168',
        projection: 'EPSG:4326'
      }),
      visible: true
    })

    // const tiandi_image_tag_map = new TileLayer({
    //     source: new XYZ({
    //         name: "天地图-影像标记",
    //         url: "http://'+MY_MAP_LEVEL_NUM+'.tianditu.com/DataServer?T=cia_c&x={x}&y={y}&l={z}&tk=65235e208cae9448e9f3a85d55c74bda",
    //         projection: "EPSG:4326",

    //     }),
    //     visible: false
    // });

    // var projectionExtent = getProjection("EPSG:4326").getExtent()
    // var size = getWidth(projectionExtent) / 256;
    // var resolutions = new Array(18);
    // var matrixIds = new Array(18);
    // for (var z = 1; z < 19; ++z) {
    //     // generate resolutions and matrixIds arrays for this WMTS
    //     resolutions[z] = size / Math.pow(2, z);
    //     matrixIds[z] = z;
    // }

    // const tiandi_WMTS_base_map = new TileLayer({
    //     source: new WMTS({
    //         url: "http://'+MY_MAP_LEVEL_NUM+'.tianditu.gov.cn/vec_w/wmts?tk=a6c1263dfd2f7f59595ae9fabdf8ccd4",
    //         layer: "vec", //注意每个图层这里不同
    //         matrixSet: "w",
    //         format: "tiles",
    //         // style: "default",
    //         projection: 'EPSG:4326',
    //         tileGrid: new WMTSTileGrid({
    //             origin: getTopLeft(projectionExtent),
    //             resolutions: resolutions,
    //             matrixIds: matrixIds,
    //         }),
    //         wrapX: true,
    //     }),
    //     visible: true
    // });

    return [tiandi_base_map, tiandi_image_map, tiandi_base_tag_map]
  }
  constructor() {}
}
export default Layers
