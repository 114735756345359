
import "ol/ol.css";
import { Map, View } from "ol";
import { defaults } from "ol/control";
import { defaults as de } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import Vector from "ol/source/Vector";
import { Style, Circle, Fill, Stroke, Text } from "ol/style";
import {
    Point as GeomPoint,
    LineString,
    MultiPolygon as GeoMultiPolygon,
    Polygon,
} from "ol/geom";
import Draw from "ol/interaction/Draw";
import Overlay from "ol/Overlay";
import { getArea, getLength } from "ol/sphere";
import { unByKey } from "ol/Observable";

// this实例对象
let vm = null


/**
 * 测距、侧面属性与方法
 * 
 */
// global so we can remove it later
let draw;
//Currently drawn feature.
let sketch;
//The help tooltip element.
let helpTooltipElement;
//Overlay to show the help messages.
let helpTooltip;
//The measure tooltip element.
let measureTooltipElement;
//Overlay to show the measurement.
let measureTooltip;
//Message to show when the user is drawing a line.
const continueLineMsg = 'Click to continue drawing the line';
const continuePolygonMsg = 'Click to continue drawing the polygon';
const createHelpTooltip = () => {
    if (helpTooltipElement) {
        helpTooltipElement.parentNode.removeChild(helpTooltipElement);
    }
    helpTooltipElement = document.createElement('div');
    helpTooltipElement.className = 'ol-tooltip hidden';
    helpTooltip = new Overlay({
        element: helpTooltipElement,
        offset: [15, 0],
        positioning: 'center-left',
    });
    vm.map.addOverlay(helpTooltip);
};
const createMeasureTooltip = () => {
    if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
    measureTooltipElement = document.createElement('div');
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
        stopEvent: false,
        insertFirst: false,
    });
    vm.map.addOverlay(measureTooltip);
}
const pointerMoveHandler = (evt) => {
    if (evt.dragging) {
        return;
    }
    let helpMsg = 'Click to start drawing';
    if (sketch) {
        const geom = sketch.getGeometry();
        if (geom instanceof Polygon) {
            helpMsg = continuePolygonMsg;
        } else if (geom instanceof LineString) {
            helpMsg = continueLineMsg;
        }
    }
    helpTooltipElement.innerHTML = helpMsg;
    helpTooltip.setPosition(evt.coordinate);
    helpTooltipElement.classList.remove('hidden');
};
const formatArea = (polygon) => {
    const area = getArea(polygon, { projection: 'EPSG:4326' });
    let output;
    if (area > 10000) {
        output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
    } else {
        output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
    }
    return output;
};
const formatLength = (line) => {
    // defaults to 'EPSG:3857 
    // need set your EPSG
    const length = getLength(line, { projection: 'EPSG:4326' });
    let output;
    if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
    } else {
        output = Math.round(length * 100) / 100 + ' ' + 'm';
    }
    return output;
};
/**
 * end
 */

export default {
    sendThis: (_this) => {
        vm = _this;
    },

    //初始地图
    createBaseMap: (id) => {
        //地图加载
        vm.map = new Map({
            interactions: de({ doubleClickZoom: false }),
            controls: defaults({
                attribution: false,
                rotate: false,
                zoom: false,
            }),
            target: id,
            layers: vm.layers,
            overlays: [],
            view: new View({
                projection: "EPSG:4326",
                center: [vm.x, vm.y],
                zoom: 13,
                maxZoom: 18,
            }),

        });
    },

    //type：LineString、Polygon 测距、侧面
    //statusFlag：true、false 选择操作、取消操作
    measure: (type, flag) => {
        vm.map.removeInteraction(draw);
        if (!flag) return

        const source = new Vector();
        const vector = new VectorLayer({
            source: source,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new Stroke({
                    color: '#ffcc33',
                    width: 2,
                }),
                image: new Circle({
                    radius: 7,
                    fill: new Fill({
                        color: '#ffcc33',
                    }),
                }),
            }),

        });
        vm.map.addLayer(vector)
        // vm.map.on('pointermove', pointerMoveHandler);
        vm.map.getViewport().addEventListener('mouseout', function () {
            helpTooltipElement.classList.add('hidden');
        });

        draw = new Draw({
            source: source,
            type: type,
            stopClick: true, //绘制时停止为地图添加的click, singleclick, doubleclick 事件。
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.5)',
                    lineDash: [10, 10],
                    width: 2,
                }),
                image: new Circle({
                    radius: 5,
                    stroke: new Stroke({
                        color: 'rgba(0, 0, 0, 0.7)',
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)',
                    }),
                }),
            }),
        });
        vm.map.addInteraction(draw);

        createMeasureTooltip();
        createHelpTooltip();

        let listener;
        draw.on('drawstart', function (evt) {
            sketch = evt.feature;
            let geometry = sketch.getGeometry();

            let tooltipCoord = evt.coordinate;
            listener = geometry.on('change', function (evt) {
                let geom = evt.target;
                let output;
                if (geom instanceof Polygon) {
                    output = formatArea(geom);
                    tooltipCoord = geom.getInteriorPoint().getCoordinates();
                } else if (geom instanceof LineString) {
                    output = formatLength(geom);
                    tooltipCoord = geom.getLastCoordinate();
                }
                measureTooltipElement.innerHTML = output;
                measureTooltip.setPosition(tooltipCoord);
            });
        });
        draw.on('drawend', function () {
            measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
            measureTooltip.setOffset([0, -7]);
            // unset sketch
            sketch = null;
            // unset tooltip so that a new one can be created
            measureTooltipElement = null;
            createMeasureTooltip();
            vm.map.removeInteraction(draw);
            unByKey(listener);
        });
    },

}